var isSP = function() {
	return $('.u-dp-s:visible').length != 0;
};

// picturefill
document.createElement('picture');

// LazyLoad
var myLazyLoad = new LazyLoad({
	elements_selector: '.lazyload'
});

$(function() {
	// ！！！PC・SP表示切り替えサンプル 不要な場合削除 ！！！
	if (isSP()) {
		// SPのみ適用させたい内容を記述
	}
	if (!isSP()) {
		// PCのみ適用させたい内容を記述
	}
	// SP header menu
	$('.c-headerMenu').click(function() {
		$(this).toggleClass('_active_');
		$('body').toggleClass('_gnav-show_');
	});
	$(".c-gnavSp-menu__close").click(function (event) {
		event.preventDefault();
		$("body").removeClass("_gnav-show_");
		$(".c-headerMenu").removeClass("_active_");
	});
	// accordion
	$('.hoge').click(function() {
		$(this).next().slideToggle();
		$(this).toggleClass('_active_');
	});
});

// tel link
var ua = navigator.userAgent.toLowerCase();
var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);

if (!isMobile) {
    $('a[href^="tel:"]').on('click', function(e) {
        e.preventDefault();
    });
}